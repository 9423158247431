






































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import IntegrationConfigModal from './IntegrationConfigModal.vue';
import IntegrationWarningModal from './IntegrationWarningModal.vue';
import { Integration } from '@/models';
import {
  IntegrationsService,
  default as defaultIntegrationsService,
} from '@/lib/services/integrations';
import { unwrapError } from '@/lib/helpers';
import { addMinutes } from 'date-fns';
import { IntegrationService } from '@/lib/services';

const OAUTH_EXPIRE_MINUTES = 15;

@Component({
  components: {
    IntegrationConfigModal,
    IntegrationWarningModal,
  },
})
export default class IntegrationCard extends Vue {
  @Prop({ required: true })
  integration: Integration;

  @Prop({ default: () => defaultIntegrationsService })
  service!: IntegrationsService;

  @Prop({ default: false, type: Boolean })
  forAccountant: boolean;

  showConfigModal: boolean = false;
  showWarningModal: boolean = false;
  connecting: boolean = false;

  async test(): Promise<void> {
    if (await this.service.test(this.integration.name)) {
      this.$toaster.success(this.prettyName, 'Test successful!');
    } else {
      this.$toaster.error(this.prettyName, 'Test failed!');
    }
  }

  edit(): void {
    this.connecting = false;
    this.showConfigModal = true;
  }

  async connect(): Promise<void> {
    this.showWarningModal = false;

    if (Object.keys(this.integration.config || {}).length > 0) {
      this.connecting = true;
      this.showConfigModal = true;
    } else {
      await this.onConnectSubmit({});
    }
  }

  async onConnectSubmit(props: Record<string, any>): Promise<void> {
    this.showConfigModal = false;
    this.$toaster.info('Connecting', this.integration.name);
    try {
      const status = await this.service.connect(this.integration.name, props);
      if (!status.done) {
        if (!status.redirect) {
          this.$toaster.error(
            'Could not integrate',
            'Redirect requested but no url present',
          );
          return;
        }

        const info = {
          expires: addMinutes(new Date(), OAUTH_EXPIRE_MINUTES),
          accountant: this.forAccountant,
          currentRoute: this.$router.currentRoute.fullPath,
          popup: status.popup,
          openConfig: !!status.openConfig,
        };

        localStorage.setItem(
          'oauth-connect-' + this.integration.name,
          JSON.stringify(info),
        );

        if (status.popup) {
          const winRef = window.open(
            status.redirect,
            'TNI Oauth connect',
            'resizeable,height=800,width=500',
          );
          if (winRef === null) {
            this.$toaster.error(
              'Could not open window',
              'Check your popup blocker',
            );
          }
        } else {
          window.location.href = status.redirect;
        }
      } else {
        this.$toaster.success('Integration connected');
        // We have to fetch integrations here, otherwise we are running the risk of
        // opening the edit modal while the data is not ready
        const integrations = await IntegrationService.all();
        this.$emit('update', integrations);
        if (status.openConfig) {
          this.edit();
        }
      }
    } catch (e) {
      this.$toaster.error('Could not add integration', unwrapError(e));
    }
  }

  async onSaveSubmit(props: Record<string, any>): Promise<void> {
    await this.saveSubmit(props);
    this.showConfigModal = false;
  }

  async onSaveAndContinue(props: Record<string, any>): Promise<void> {
    await this.onSaveSubmit(props);
    this.$nextTick(() => (this.showConfigModal = true));
  }

  async saveSubmit(props: Record<string, any>): Promise<void> {
    try {
      await this.service.update(this.integration.name, props);
    } catch (e) {
      this.$toaster.error('Could not update integration', unwrapError(e));
    }
  }

  async remove(): Promise<void> {
    try {
      await this.service.delete(this.integration.name);
      this.$emit('refresh');
    } catch (e) {
      this.$toaster.error('Could not delete integration', unwrapError(e));
    }
  }

  get imageUrl(): string {
    const publicDir = process.env.BASE_URL;
    return `${publicDir}static/logos/${this.integration.name}.png`;
  }

  get prettyName(): string {
    return this.$tc(
      `settings.integrations.types.${this.integration.name.toLowerCase()}`,
    );
  }
}
