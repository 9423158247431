


















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class IntegrationWarningModal extends Vue {
  private chargesAccepted: boolean = false;

  private connect() {
    this.$emit('connect');
  }

  private close() {
    this.$emit('close');
  }
}
